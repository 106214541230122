import dayjs from 'dayjs';
const format = 'M/D/YYYY H:m:s';

export interface IScheduleData {
    enableCountdown?: boolean;
    startDate?: string;
    endDate?: string;
    startTime?: string;
    endTime?: string;
    timeZone?: string | undefined;
}

// convert timezone choice to matching timezone in moment-timezone
export const getTimeZone = (timeZone: string): string | undefined => {
    switch (timeZone) {
        case 'hawaii':
            return 'America/Adak';
        case 'alaska':
            return 'America/Anchorage';
        case 'pacific':
            return 'America/Los_Angeles';
        case 'mountain':
            return 'America/Denver';
        case 'central':
            return 'America/Chicago';
        case 'eastern':
            return 'America/New_York';
        default:
            return undefined;
    }
};

// convert time to local time
export const convertToLocal = (time: dayjs.Dayjs, schedule: IScheduleData): dayjs.Dayjs | undefined => {
    const timeZone = schedule.timeZone;

    return !timeZone ? time : dayjs(time.format(format), format).tz(timeZone).local();
};

// checks if countdown/banner is currently scheduled
export const isScheduled = (schedule: IScheduleData, showExpired = false): Boolean => {
    if (!schedule.enableCountdown) {
        return false;
    }
    // let isScheduled = false;

    let start = schedule.startDate && schedule.startTime && dayjs(`${schedule.startDate} ${schedule.startTime}`, format);
    let end = schedule.endDate && schedule.endTime && dayjs(`${schedule.endDate} ${schedule.endTime}`, format);

    // check if schedule is valid
    const validStart = start && start.isValid();
    const validEnd = end && end.isValid();

    if (!validStart || !validEnd) {
        return false;
    }

    const now = dayjs();

    // convert to local time
    start = start && convertToLocal(start, schedule);
    end = end && convertToLocal(end, schedule);

    if (showExpired) {
        return now.isAfter(start);
    } else {
        return now.isBetween(start, end);
    }
};
