import { callUtagLink } from "./utagCaller";

export interface IUtagPromoConfig {
    promotion_impression_name?: string[];
    promotion_impression_id?: string[];
    promotion_impression_creative?: string[];
    promotion_impression_position?: (string | undefined)[];
    promotion_name?: string[];
    promotion_id?: string[];
    promotion_creative?: string[];
    promotion_position?: (string | undefined)[];
}

export interface IUtagGeneralConfig {
    enhanced_action?: string | undefined;
}
export interface ICustomerConfig {
    customer_email?: string;
}
export interface IProductConfig {
    product_id?: string[];
    product_name?: string[];
    product_category?: (string | undefined)[];
    product_brand?: (string | undefined)[];
    product_variant?: (string | undefined)[];
    product_price?: (string | undefined)[];
    product_list_name?: string[];
    product_position?: (number | undefined)[];
    product_quantity?: (string | undefined)[];
    checkout_step?: string;
}
export interface IProductImpressionConfig {
    product_impression_brand?: (string | undefined)[];
    product_impression_list_name?: (string | undefined)[];
    product_impression_id?: string[];
    product_impression_name?: string[];
    product_impression_category?: string[];
    product_impression_variant?: (string | undefined)[];
    product_impression_position?: number[];
    product_impression_price?: number[];
}
export interface IAnalyticsConfiguration {
    event?: string | null;
    eventName?: string | null;
    eventCategory?: string | null;
    eventAction?: string | null;
    eventLabel?: string | null;
    eventType?: string | null;
    eventNoninteraction?: string;
    enhancedAction?: string | null;
    searchTerm?: string | undefined | null;
    searchType?: string | undefined | null;
    generalConfig?: IUtagGeneralConfig;
    promoConfig?: IUtagPromoConfig;
    productConfig?: IProductConfig;
    customerConfig?: ICustomerConfig;
    productImpressionConfig?: IProductImpressionConfig;
}

export const fireAnalyticsLink = ({
    searchTerm = null,
    searchType = null,
    eventName = null,
    eventCategory = null,
    eventAction = null,
    eventLabel = null,
    eventType = null,
    eventNoninteraction = 'false',
    enhancedAction = null,
    generalConfig = {},
    promoConfig = {},
    productConfig = {},
    customerConfig = {},
    productImpressionConfig = {}
}: IAnalyticsConfiguration) => {
    void callUtagLink({
        ...generalConfig,
        ...promoConfig,
        ...productConfig,
        ...customerConfig,
        ...productImpressionConfig,
        event: eventName,
        event_category: eventCategory,
        event_action: eventAction,
        event_label: eventLabel,
        event_type: eventType,
        event_noninteraction: eventNoninteraction,
        enhanced_action: enhancedAction,
        search_term: searchTerm,
        search_type: searchType
    });
};