import { fireAnalyticsLink } from './clickTrack';

interface IPromotionConfigs {
    eventLabel: string;
    promotionName: string[];
    promotionId: string[];
    promotionCreative: string[];
    promotionPosition: (string | undefined)[];
}

export const firePromotionImpression = ({ eventLabel = '', promotionName = [], promotionId = [], promotionCreative = [], promotionPosition = [] }: IPromotionConfigs) => {
    fireAnalyticsLink({
        eventName: 'promotion-impression',
        eventCategory: 'ecommerce',
        eventAction: 'promotion impression',
        eventLabel: eventLabel,
        eventNoninteraction: 'true',
        promoConfig: {
            promotion_impression_name: promotionName,
            promotion_impression_id: promotionId,
            promotion_impression_creative: promotionCreative,
            promotion_impression_position: promotionPosition
        }
    });
};

export const firePromotionClick = ({ eventLabel = '', promotionName = [], promotionId = [], promotionCreative = [], promotionPosition = [] }: IPromotionConfigs) => {
    fireAnalyticsLink({
        eventName: 'promotion-click',
        eventCategory: 'ecommerce',
        eventAction: 'promotion click',
        eventLabel: eventLabel,
        eventNoninteraction: 'false',
        promoConfig: {
            promotion_name: promotionName,
            promotion_id: promotionId,
            promotion_creative: promotionCreative,
            promotion_position: promotionPosition
        }
    });
};