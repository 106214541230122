import { IAnalyticsConfiguration } from './clickTrack';

// Interface for 3rd-party function. AKA utag.
interface ITealium {
    link(data: {}): void | false;
    view(data: {}): void | false;
    data(data: {}): void | false;
}

export interface IPromotionConfig {
    event?: string | null;
    event_name?: string | null;
    search_term?: string | null;
    search_type?: string | null;
    event_category?: string | null;
    event_action?: string | null;
    event_label?: string | null;
    event_type?: string | null;
    event_noninteraction?: string;
    enhanced_action?: string | null;
    customer_email?: string | undefined;
    customer_phone_number?: string | undefined;
    first_name?: string | undefined;
    last_name?: string | undefined;
    address1?: string | undefined;
    address2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip_code?: string | undefined;
    order_subtotal?: string | undefined;
    order_currency?: string | undefined;
    country?: string | undefined;
    // productConfig?: IProductDataConfig;
    checkout_step?: string;
    checkout_option?:(string | undefined)[];
    checkout_type?:(string | undefined)[];
    product_id?: (string | undefined)[];
    product_name?: (string | undefined)[];
    product_category?: (string | undefined)[];
    product_brand?: (string | undefined)[];
    product_variant?: (string | undefined | number)[];
    product_price?:  (string | undefined)[];
    product_quantity?:  (string | undefined)[];
    product_url?: (string | undefined)[];
    product_size?: (string | undefined)[];
    product_image_url?: (string | undefined)[];
    core_item_flag?: string | null;
    funnel_type?: string | null;
    ga_title?: string | null;
    ga_location?: string | null;
    order_quantity?: string | null;
    master_customer_code?: string | null;
    order_number?: string | null;
}

interface IProductDataConfig {
    product_variant?: (string | undefined)[];
    product_price?: (string | undefined)[];
}

export interface IAnalyticsConfigurationData {
    productConfig?: IProductDataConfig;
}


// Legacy function
export const callUtag = async (objToCall: IPromotionConfig) => {
    void await callUtagLink(objToCall);
};

/**
 * Tealium dispatch click tracking.
 * @param objToCall
 */
export const callUtagLink = async (objToCall: IPromotionConfig | IAnalyticsConfiguration) => {
    try {
        Object.keys(objToCall).forEach(
            key => {
                if (objToCall[key] === null) {
                    delete objToCall[key];
                }
            }
        );
        // If utag doesn't exist in the global scope, set as false.
        const utag: ITealium = global.hasOwnProperty('utag') ? global["utag"] : false;
        if(utag) { utag.link(objToCall); }

    } catch (e) {
        console.log('Utag Error', e);
    }
};

/**
 * Tealium dispatch view tracking.
 * @param objToCall
 */
export const callUtagView = async (objToCall: {}) => {
    try {
        // If utag doesn't exist in the global scope, set as false.
        const utag: ITealium = global.hasOwnProperty('utag') ? global["utag"] : false;
        if(utag) { utag.view(objToCall); }
    } catch (e) {
        console.log('utag View Error', e);
    }
};

/**
 * Not currently implemented.
 * @param objToCall
 * @param dataType
 */
export const callUtagData = ({ productConfig = {} }: IAnalyticsConfigurationData , dataType: string) => {
    try {
        // If utag doesn't exist in the global scope, set as false.
        const utag: ITealium = global.hasOwnProperty('utag') ? global["utag"] : false;
        switch (dataType) {
            case 'data':
                // @ts-ignore
                if('utag' in window) { utag.data = {...utag.data, ...productConfig}; }
                break;
            case '_data':
                // @ts-ignore
                utag_data = {...utag_data , ...productConfig};
                break;
            default:
                null;
        }
    } catch (e) {
        console.log('Utag Error', e);
    }
};