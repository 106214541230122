import MsDyn365, { IImageData } from '@msdyn365-commerce/core';
import { ITealiumEventData, ITealiumPromotionData } from './mfrm-content-reel.props.autogenerated';

export const getAnalyticsConfig = (promoName: string, carouselIndex: number, tEvent: ITealiumEventData | undefined) => {
    // GENERAL ANALYTICS CONFIG
    if (tEvent) {
        return {
            eventName:
                tEvent.event ? tEvent.event : 'content-reel',
            eventCategory:
                tEvent.event_category ? tEvent.event_category : 'content-reel',
            eventAction:
                tEvent.event_action ? tEvent.event_action : `card ${carouselIndex + 1} click`,
            eventLabel:
                tEvent.event_label ? tEvent.event_label : promoName
        };
    } else {
        return {
            eventName: 'content-reel',
            eventCategory: 'content-reel',
            eventAction: `card ${carouselIndex + 1} click`,
            eventLabel: promoName
        };
    }
};

export const getPromoAnalyticsConfig = (promoName: string, image: IImageData | undefined, linkTo: string, carouselIndex: number, tPromotion: ITealiumPromotionData | undefined) => {
    // PROMO ANALYTICS CONFIG
    const promotionCreative = image ? image.title || image.altText || '' : '';
    const pagePath = MsDyn365.isBrowser ? (window.location.href.split(location.host)[1].includes('?') ? window.location.href.split(location.host)[1].split('?')[0] : window.location.href.split(location.host)[1]) : '';

    if (tPromotion) {
        return {
            eventLabel:
                tPromotion.promotion_name ? `${tPromotion.promotion_name} from ${pagePath}` : `${promoName} from ${pagePath}`,
            promotionName:
                tPromotion.promotion_name ? [tPromotion.promotion_name] : [promoName],
            promotionId:
                tPromotion.promotion_id ? [tPromotion.promotion_id] : [linkTo],
            promotionCreative:
                tPromotion.promotion_creative_version ? [tPromotion.promotion_creative_version] : [promotionCreative],
            promotionPosition:
                tPromotion.promotion_position ? [tPromotion.promotion_position] : [`${carouselIndex + 1}`]
        };
    } else {
        return {
            eventLabel: `${promoName} from ${pagePath}`,
            promotionName: [promoName],
            promotionId: [linkTo],
            promotionCreative: [promotionCreative],
            promotionPosition: [`${carouselIndex + 1}`]
        };
    }

};