import MsDyn365, { ICoreContext } from '@msdyn365-commerce/core';
export type GridSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const _getViewPort = (context: ICoreContext): GridSize => {
    if (MsDyn365.isBrowser && window.innerWidth) {
        const gridSettings = context.request.gridSettings;
        if (gridSettings) {
            if (gridSettings.xs && window.innerWidth <= gridSettings.xs.w) {
                return 'xs';
            } else if (gridSettings.sm && window.innerWidth <= gridSettings.sm.w) {
                return 'sm';
            } else if (gridSettings.md && window.innerWidth <= gridSettings.md.w) {
                return 'md';
            } else if (gridSettings.lg && window.innerWidth <= gridSettings.lg.w) {
                return 'lg';
            } else {
                return 'xl';
            }
        }
    }
    return 'xs';
};

export default _getViewPort;
